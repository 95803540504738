import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import PropTypes from 'prop-types';
import Head from 'next/head';
import {ThemeProvider} from '@mui/material/styles';
import AdapterMoment from '@mui/lab/AdapterMoment';
import CssBaseline from '@mui/material/CssBaseline';
import {CacheProvider} from '@emotion/react';
import theme from 'theme';
import createEmotionCache from 'helpers/createEmotionCache';
import 'styles/global.scss';
import 'react-image-crop/src/ReactCrop.scss';
import {LocalizationProvider} from '@mui/x-date-pickers';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
    const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <link rel='icon' href='/favicon.ico' />
                <title>New phoenix</title>
                <meta name='viewport' content='initial-scale=1, width=device-width' />
            </Head>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <Component {...pageProps} />
                </ThemeProvider>
            </LocalizationProvider>
        </CacheProvider>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    emotionCache: PropTypes.object,
    pageProps: PropTypes.object.isRequired
};
