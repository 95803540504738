import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
    shape: {
        borderRadius: 10,
    },
    palette: {
        primary: {
            main: '#67B580',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#FF5F5B',
            contrastText: '#ffffff',
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        h1: {
            fontWeight: 500,
            fontSize: 35,
            letterSpacing: '-0.24px'
        },
        h2: {
            fontWeight: 500,
            fontSize: 29,
            letterSpacing: '-0.24px'
        },
        h3: {
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: '-0.06px'
        },
        h4: {
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '-0.06px'
        },
        h5: {
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.05px'
        },
        h6: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '-0.05px'
        },
    },
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                contained: {
                    marginRight: 0,
                    marginLeft: 0,
                    position: "absolute",
                    bottom: -20,
                    left: 5,
                },
            },
        },
    }
});

export default theme;
